.galleryImg img{
  max-width: 300px;
  max-height: 300px;
}

.galleryImg {
  text-align: center;
  margin: 20px 0 40px 0;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.artistArea {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.artistNameValue {
  font-weight: 800;
  margin-bottom: 20px;
}

.artistDetail{
  width: 700px;
  line-height: 1.5;
  font-size: 12px;
}

.galleryItem {
  width: 340px;
  margin-bottom: 50px;
}

.galleryBox {
  border: 1px solid #bdb7b7;
  padding: 10px;
}

.titleLabel {
}

.titleValue {
}

.galleryInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.value {
  font-weight: 800;
}

.label {
  font-size: 12px;
}

.priceValue {
  text-align: right;
}

.galleryDescription {
  padding: 15px;
}

.descriptionValue {
  line-height: 1.5;
  margin-top: 10px;
}

.galleryArea {
  display: flex;
  justify-content: space-around;
  padding: 50px 200px;
  flex-wrap: wrap;
}

.lineBottom {
  width: 100%;
  border: 1px solid #bdb7b7;
}

.titleValue {
  overflow: hidden;
  height: 40px;
}

.galleryNFT {
  overflow: hidden;
  width: 200px;
  text-align: center;
}

.galleryLogo {
  padding-bottom: 100px;
}

.galleryLogo img {
  width: 300px;
  margin-top: 0px;
}

@media screen and (max-width: 414px) {
  .artistDetail {
    width: 300px;
  }

  .galleryLogo {
    padding-bottom: 50px !important;
  }

  .galleryLogo img {
    width: 150px !important;
    margin-top: 50px !important;
  }

  .galleryArea {
    padding: 50px 20px;
  }

  .descriptionLabel {
    font-size: 12px !important;
  }
}