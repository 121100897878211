.App {
  text-align: center;
}

.App-logo {
  height: 1.5em;
  cursor: pointer;
  z-index: 2;
}

.App-header{
  background-color: black;
  height: 10vh;
  padding: 0 5vmax;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
}

.App-main {
  min-height: 100vh;
  left: 0;
  bottom: 0;
  right: 0;
}

.bgBlack {
  background-color: black;
}
.App-footer {
  min-height: 10vh;
  /* background-color: #6229ca; */
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.App-hero {
  width: 100%;
  /* height: 30vmin; */
  height: calc(100vw * 0.2859);
  /* min-height: 150px; */
  background-color: #1e7157;
  position: relative;

  font-weight: 700;
  font-family: 'Titillium Web', 'Roboto', 'Avenir', 'Helvetica Neue', sans-serif;
  color: white;
  text-shadow: 2px 2px #000;
  font-size: 4rem;
  line-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-hero * {
  position: relative;
  margin: 0;
}

.App-hero small {
  color: #bbb;
  line-height: 3rem;
  font-size: 1.3rem;
  text-shadow: none;
}

.App-hero-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  /* filter: blur(1px);
  -webkit-filter: blur(1px); */
  background-image: url("../assets/Gigi-NFT-Banner.webp");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  opacity: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 600px) {
  .App-hero {
    line-height: 2.5rem;
    font-size: 2.5rem;
  }
  .App-hero small {
    font-size: 1rem;
  }
}

#opensea-iframe {
  height: 1800px;
}

.disclaimerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.disclaimerContent {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  margin: 100px 0;
}

.disclaimerTtile {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 50px;
}
.disclaimerDescription {
  margin: 20px;
  line-height: 30px;
  text-align: justify;
}

.iframeCustom {
  height: 1000px;
}

/************************************** New Layout **************************************/
.marketplaceContainer {
  background-color: rgb(18, 18, 18);
  background-color: white;
}

.marketplaceContent {
  display: flex;
  padding: 50px 0;
}

.marketplaceImg {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex: 1 0 auto;
}

.imgContainer {
  padding: 0 10%;
  max-width: 1020px;
}

.imgContainer img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  margin-top: 50px;
}
.marketplaceDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 32px;
  /* color: white; */
  width: 450px;
}

.tvbTitle {
  font-size: 30px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 50px;
}

.tvbDescription {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 23px;
  text-align: left;
  line-height: 26px;
}

.tvbLink {
  /* color: rgba(255, 255, 255, 0.65); */
  font-weight: 600;
  text-decoration: none;
  overflow-wrap: break-word;
  text-align: left;
  line-height: 1.4;
  width: inherit;
}

.tvbLink:hover {
  /* color: rgba(255, 255, 255); */
}

.tvbBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  width: inherit;
}
.tvbBtn {
  border-color: rgb(45, 129, 255);
  color: rgb(255, 255, 255);
  background: rgb(45, 129, 255);
  width: 216px;
  text-align: center;
  padding: 15px 0;
  font-weight: 800;
  border-radius: 48px;
}

.tvbBtn:hover {
  filter: brightness(110%);
  cursor: pointer;
}

.smallCross {
  /* border-color: rgba(255, 255, 255,0.65); */
  border-color:black;
  width: inherit;
  border-top: 1px solid;
  margin: 30px 0;
}

.tvbDescriptionGroup {
  width: inherit;
  /* height: 600px;
  overflow-y: scroll; */
}

.tvbDescriptionGroup::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tvbDescriptionGroup{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}