@media screen and (max-width: 1450px) {
  .marketplaceImg {
    flex: auto !important;
  }
  
}

@media screen and (max-width: 1170px) {
  .marketplaceDetail {
    width: 360px !important;
  }
  
}
@media screen and (max-width: 900px) {
  .marketplaceDetail {
    margin-top: 50px;
  }
  .marketplaceImg {
    flex: auto !important;
  }
  
}

@media screen and (max-width: 570px) {
  .formOnHeader {
    display: none !important;
  }
  .App-header img {
    height: 1.5em;
  }
}

@media screen and (max-width: 550px) {
  .marketplaceContent {
    flex-direction: column;
    align-items: center;
  }
  .marketplaceDetail {
    width: 98% !important;
    align-items: center !important;
    padding: 0 16px !important;
  }
  .tvbTitle {
    width: inherit;
  }
  .tvbDescriptionGroup {
    height: auto !important;
    overflow:visible !important
  }

  .imgContainer img {
    margin-top: 0;
  }

  .marketplaceContent {
    padding-top: 0 !important;
  }

  .imgContainer {
    padding: 0 16px !important;
  }
}

@media screen and (max-width: 414px) {
  .subscript-form input::placeholder {
    margin-left: 30px;
  }
  .subscript-form input {
    padding: 15px 100px 15px 36px;
  }

  .subscript-form .submit-button {
    padding: 19px 20px;
    margin-left: -109px !important;
  }

  .onwerNFT {
    flex-direction: column;
  }

  .nftInfo {
    flex-direction: column;
  }

  .videoLowRisk {
    margin-right: 0px !important;
    margin-top: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;  
}

  .each-slide > div {
    height: 200px;
  }

  .each-slide span {
    padding: 10px 20px;
    font-size: 16px;
  }

  .containerNFT {
    margin-top: 50px;
  }

  .contentNFT {
    flex-direction: column;
  }

  .videoNFT {
    width: 100% !important;
    height: 150px !important;
  }

  .nftLogo {
    height: 150px;
    padding-top: 50px;
  }

  .nftLogo img {
    width: 100px;
    margin-top: 70px;
  } 

  .authorNFT {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .authorAddress {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  .authorDetail {
    width: 100%;
    text-align: center;
  }

  .bottomBar {
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: 100px;
    color: white;
  }

  .bottomBar img {
    height: 40px;
    margin-bottom: 10px;
  }

  .bottomBar div {
    font-size: 10px;
  }

  .ownerNFT {
    padding: 10px 10px;
  }

  .titleNFT {
    font-size: 24px;
  }
  .horizonTitleNFT {
    width: 50%;
  }

  .contentNFT {
    height: auto;
  }

  .orderNFT {
    text-align: center;
  }

  .imgNFT {
    height: auto;
    margin: 10px 0px;
  }
  .imgNFT img {
    height: 120px;
  }
  .infoNFT {
    height: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .downloadBtn {
    right: 0;
    left: 0;
    margin: auto;
    width: 75px;
    text-align: center;
    margin-top: 20px;
    position: relative;
  }

  .authorContent {
    text-align: justify;
    padding: 0 10px;
  }

  .introSmall {
    padding: 0 20px;
  }

  .tvbAddress {
    margin-right: 0 !important;
    margin-top: 0;
  }

  .tvbAuthor .authorContent{
    padding: 0px 40px;
  } 

  .mainImg {
    padding: 10px 40px;
  }

  .tvbNFT {
    font-size: 22px;
  }

  .tvbAuthor .authorTitle {
    margin-top: 70px;
  }

  .tvbContainer {
    margin-top: 70px;
  }

  #opensea-iframe {
    height: 3100px !important;
  }
}

@media screen and (max-width: 375px) {
  .each-slide > div {
    height: 160px;
  }
}